<template>
  <div :style="slidePosition" class=" z-4 w-100 h-100">
    <div
      class="position-absolute bg-summer-sky-0-5 border-warning text-warning square18"
      style="top:50%; left:42.5%;width:55%;padding:2.5% 2.5%;transform: translateY(-50%);"
    >
      <h6 class="text-white font-vw-3-15 font-w-600 fade18 " style="opacity:0;">
        1999
      </h6>
      <h6
        class="text-white font-vw-3-15 font-w-600 fade18 my-0"
        style="opacity:0;"
      >
        RESHAPING OUR VISION
      </h6>
      <p class="mt-3 text-white font-vw-1-35 text18 mb-0" style="opacity:0;">
        TRIUMF’s ability to produce rare isotope beams sparked a wave of
        diverse, world-leading, onsite experimental facilities. TRIUMF has
        produced more than 1000 different rare isotopes since 1999; in many
        cases, this was the first instance of the isotope’s experimental use.
        The subsequent development, installation, and commissioning of Canada’s
        first superconducting linear accelerator in 2006, and the production of
        intense beams of isotopes such as lithium-11, put ISAC and TRIUMF on the
        map as a top radioactive beam facility. In collaboration with TRIUMF
        theorists, these experiments are revolutionizing our understanding of
        nuclear structure by providing a window into the nuclei of extremely
        rare isotopes with exotic configurations of neutrons and protons.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },

  mounted() {
    //this.startLoop()
  },
  methods: {
    slide18(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1000
      })

      animation
        .add({
          targets: ".square18",
          opacity: [0, 1],
          translateY: ["50%", "-50%"],
          duration: 600,
          delay: 5000,
          easing: "easeInQuart"
        })
        .add({
          targets: ".fade18",

          translateY: ["1vh", "0vh"],
          opacity: [0, 1],
          duration: 1000,
          easing: "easeInOutCubic",
          delay: (el, i) => 22000 + 1200 * i
        })
        .add({
          targets: ".text18",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 1200,
          delay: 2000,
          easing: "easeInOutCubic"
        })

      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * (1 - percentage)
      )
    }
  },
  watch: {
    innerPosition() {
      this.slide18(this.innerPosition)
    }
  }
}
</script>

<style></style>
